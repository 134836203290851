// utils/API.js

import axios from "axios";
import PARAMS from "utils/PARAMS";
import { toast } from 'react-toastify';


// Instancia para api de partes
var instance = axios.create({
  baseURL: PARAMS.apiUrl,
  timeout: 10000
});

instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  controlResponse(error);
  return Promise.reject(error);
});

// Instancia para api de partes
var instance_blob = axios.create({
  baseURL: PARAMS.apiUrl,
});
instance_blob.defaults.responseType = 'blob';
instance_blob.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("apiToken");
instance_blob.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  controlResponse(error);
  return Promise.reject(error);
});


function controlResponse(error){
  if (error.response) {
    switch (error.response.status) {
      case 401:
        if (localStorage.getItem("apiToken")) {
          toast("Sesión caducada", {type: "error"});
          axios.get('logout');
          localStorage.removeItem('apiToken');
          localStorage.removeItem('userType');
          window.location.replace(PARAMS.baseUrl);
        }else{
          toast("Usuario no autorizado", {type: "error"});
        }
        break;
      case 404:
        toast("Url no disponible", {type: "error"});
        break;
      case 500:
        break;
      default:

    }
  }else{
    toast("No se ha podido establecer conexión", {type: "error"});
  }
}

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch(e) {
    resolved.error = e;
  }

  return resolved;
}

// Ruta para verificar tipo de usuario (token)
export async function isAdminAuth() {
  return await resolve(
    instance.post('isAdminAuth').then(res => res.data)
  )
}

// Ruta para verificar tipo de usuario (no token)
export async function isAdminGuest() {
  return await resolve(
    instance.post('isAdminGuest').then(res => res.data)
  )
}

export async function login(email, password, userType) {
  return await resolve(
    instance.post('login', { email, password, userType }).then(res => res.data)
  )
}

export async function register(nombre, email, password, userType) {
  return await resolve(
    instance.post('register', { nombre, email, password, userType }).then(res => res.data)
  )
}

export async function resetPass(email) {
  return await resolve(
    instance.post('resetPassword/create', { email }).then(res => res.data)
  )
}

export async function logout() {
  return await resolve(
    instance.get('logout').then(res => res.data)
  )
}

export async function getPermiso() {
  return await resolve(
    instance.post('permiso/index').then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Usuarios -------------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function getUser() {
  return await resolve(
    instance.post('usuarios/show').then(res => res.data)
  )
}

export async function userIndex(search, page, perPageData) {
  return await resolve(
    instance.post('usuarios/index', { search, page, perPageData }).then(res => res.data)
  )
}

export async function userStore(user) {
  return await resolve(
    instance.post('usuarios/store', user).then(res => res.data)
  )
}

export async function userUpdate(user) {
  return await resolve(
    instance.post(`usuarios/update/${user.id}`, user).then(res => res.data)
  )
}

export async function userDestroy(id) {
  return await resolve(
    instance.post(`usuarios/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Permisos -------------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function permisosList(search) {
  return await resolve(
    instance.post('permiso/list', { search }).then(res => res.data)
  )
}

export async function permisoStore(nombre, permiso_modelos) {
  return await resolve(
    instance.post('permiso/store', { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoUpdate(id, nombre, permiso_modelos) {
  return await resolve(
    instance.post(`permiso/update/${id}`, { nombre, permiso_modelos }).then(res => res.data)
  )
}

export async function permisoDestroy(id) {
  return await resolve(
    instance.post(`permiso/destroy/${id}`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Respuestas -----------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function cargarVariables(variables, user_id) {
  return await resolve(
    instance.post(`respuesta/cargar`, {variables, user_id}).then(res => res.data)
  )
}

export async function checkVariablesIniciales(user_id) {
  return await resolve(
    instance.post(`respuesta/check_variables_iniciales`, {user_id}).then(res => res.data)
  )
}

export async function duplicarFormulario(user_id, formulario, formulario_copiar) {
  return await resolve(
    instance.post(`respuesta/copiar_variables_formulario`, {user_id, formulario, formulario_copiar}).then(res => res.data)
  )
}

export async function checkVariablesFromulario(user_id, type) {
  return await resolve(
    instance.post(`respuesta/check_variables_formulario`, {user_id, type}).then(res => res.data)
  )
}

export async function enviarVariableAPI(variable, value, orden = null, user_id = null) {
  return await resolve(
    instance.post(`respuesta/guardar`, {variable, value, orden, user_id}).then(res => res.data)
  )
}

export async function eliminarVariable(variable, orden, user_id = null) {
  return await resolve(
    instance.post(`respuesta/eliminar`, {variable, orden, user_id}).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Archivos -------------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function guardarArchivo(variable, file) {
  let data = new FormData();
  data.append('file', file);
  data.append('variable', variable);
  let settings = { headers: { 'content-type': 'multipart/form-data' } }

  return await resolve(
    instance.post(`guardarArchivo`, data, settings).then(res => res.data)
  )
}

export async function obtenerArchivo(variable, user_id = null) {
  let data = new FormData();
  data.append('variable', variable);
  data.append('user_id', user_id);

  return await resolve(
    instance.post(`obtenerArchivo`, data).then(res => res.data)
  )
}

export async function borrarArchivo(variable, file_name) {
  let data = new FormData();
  data.append('nombre_archivo', file_name);
  data.append('variable', variable);

  return await resolve(
    instance.post(`borrarArchivo`, data).then(res => res.data)
  )
}

export async function descargarPDF(url) {
  toast("Descarga en proceso.", {type: "success"});
  return await resolve(
    instance.post(`pdf/obtenernombre`, {url}).then(res => blobToPDF(res.data, url))
  )
}

async function blobToPDF(nombre, url){
  let data = new FormData();
  data.append('nombrearchivo', nombre);
  await instance_blob.post(`pdf/${url}`, data)
  .then(res => {
    if (res) {
      //Descargar
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      console.log(nombre);
      link.setAttribute('download', nombre);
      document.body.appendChild(link);
      link.click();
      toast("Descarga realizada correctamente.", {type: "success"});
    }
  })
  .catch(err => {
    toast("Ha ocurrido un error en la descarga.", {type: "error"});
    console.log(err);
  });
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Informes/Excel -------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function respuestasIndex() {
  return await resolve(
    instance.post(`respuesta/index`).then(res => res.data)
  )
}

export async function excel() {
  return await resolve(
    instance.post(`respuesta/excel`).then(res => res.data)
  )
}

/** --------------------------------------------------------------------------------------
* ----------------------------------- Logs -----------------------------------------------
* ----------------------------------------------------------------------------------------*/

export async function logIndex() {
  return await resolve(instance.post("log/index").then((res) => res.data));
}
