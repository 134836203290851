import React, { lazy, Suspense, useState, useEffect } from "react";
import UserParams from 'utils/UserParams';
import LoginAdmin from "views/admin/LoginAdmin";
import LoginWeb from "views/web/LoginPage/LoginPage";
import PARAMS from "utils/PARAMS";
import * as API from "utils/API_V2";
import GridContainer from "components/Grid/GridContainer";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { createBrowserHistory } from "history";


const Admin = lazy(() => import('layouts/Admin'));
const Web = lazy(() => import('layouts/Web'));
const hist = createBrowserHistory();
const loading = () => <CustomLoading/>;

export default function App(prop) {

  const [apiToken, setApiToken] = useState(localStorage.getItem("apiToken") || "");
  const [user, setUser] = useState(null);
  const [login, setLogin] = useState(false);
  const [load, setLoad] = useState(false);

  const isAdmin = async(e) => {
    let res;
    if (apiToken != '') {
      res = await API.isAdminAuth();
    }else {
      res = await API.isAdminGuest();
    }

    if (res.error) {
      setLogin(false)
    }else{
      setUser(res.data)
      if (res.data.rol == 'admin') {
        UserParams.setReadOnly(true);
      }else{
        UserParams.setReadOnly(false);
      }
      setLogin(true)
    }
    setLoad(true)
  }

  useEffect(() => {
    isAdmin();
  }, []);


  if (load) {
    if(login){
      return (
        <Suspense fallback={loading()}>
          <Router history={hist}>
          {(user.rol == 'admin') && <Switch>
            <Route path="/" component={(props) => <Admin user={user} {...props} />} />
          </Switch>}
          {(user.rol == 'front') && <Switch>
            <Route path="/" component={(props) => <Web user={user} {...props} />} />
          </Switch>}
          </Router>
        </Suspense>
      );
    }else{
      switch (true) {
        case window.location.href.includes(PARAMS.panel_url):
          return(<LoginAdmin setApiToken={(token) => setApiToken(token)} />)
          break;
        default:
          return(<LoginWeb setApiToken={(token) => setApiToken(token)} />)
      }
    }

  }else{
    return(
      <CustomLoading/>
    )
  }

}
