var UserParams = (function() {

  var getReadOnly = function() {
    return sessionStorage.getItem('readOnly') === 'true' ? true : false;
  };

  var setReadOnly = function(state) {
    sessionStorage.setItem('readOnly', state);
  };

  return {
    getReadOnly: getReadOnly,
    setReadOnly: setReadOnly
  }

})();

export default UserParams;
