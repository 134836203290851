/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PARAMS from "utils/PARAMS";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer style ={{position: 'absolute', bottom: 0, width: '100%', height: '4rem', backgroundColor: PARAMS.firstColor, zIndex: 5}} >
      <div className={classes.container} style={{paddingBottom: 0}}>
        <div className={classes.left}></div>
        <div className={classes.right} style={{color: '#fff'}}>
          &copy; {1900 + new Date().getYear()}{" "}
          <a
            href="https://www.aicor.com/"
            target="_blank"
            className={classes.a}
            style={{color: '#fff'}}
          >
            Aicor
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
