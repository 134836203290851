/*eslint-disable*/
import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import PARAMS from 'utils/PARAMS';

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import { descargarPDF } from "utils/API_V2";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const [userName, setUserName] = React.useState(null);

  useEffect(() => {
    setUserName(localStorage.getItem("userName"));
  }, []);

  const logout = () => {
    axios.get('logout');
    localStorage.removeItem('apiToken');
    localStorage.removeItem('userType');
    window.location.replace(PARAMS.baseUrl);
  }

  const classes = useStyles();

  return (<div style={{display: 'flex', flexDirection: 'row'}}>
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Descargas"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link className={classes.dropdownLink} onClick={() => descargarPDF("downloadSolicitudAdministrativo")}>
              Solicitud Administrativo
            </Link>,
            <Link className={classes.dropdownLink} onClick={() => descargarPDF("downloadSolicitudTecnico")}>
              Solicitud Técnico
            </Link>,
          ]}
        />
      </ListItem>
    </List>
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={userName}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink} onClick={() => logout()}>
              Cerrar sesión
            </Link>
          ]}
        />
      </ListItem>

    </List>
  </div>);
}
